import React from "react"
import Layout from "../components/layout"
import DisqueBox from "../components/disqueBox"
import { graphql } from 'gatsby'
import Seo from "../components/seo"



const Discographie = ({data}) => {
  
  return (
  <Layout>
    <Seo meta={data.alldisques.seoMetaTags} />
      <h1 className="breadcrumb">Discographie</h1>
      <div className="container">
      { data.alldisques.disques.map(( disque,i) => ( 
          <DisqueBox key={i} {...disque} />
        ))
        }
      </div>
  </Layout>
  )
}

export default Discographie

export const dicographieQuery = graphql`
  query {
    alldisques:datoCmsPageDiscographie {
      seoMetaTags  {
        ...GatsbyDatoCmsSeoMetaTags
      }
      disques {
        
          id
          titre
          date
          detail
          slug
          image  {
            gatsbyImageData (
              placeholder: BLURRED
            )
            alt
          }
        
      }  
  }
}`