import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import styled from 'styled-components';
import { Link } from 'gatsby'
import FormatDate  from './formatDate'
import { Fade } from "react-reveal"

const Flex = styled(Link)`
    
    max-width:1000px;
    display: flex;
    align-items: flex-start;
    flex-direction:row;
    margin: 7rem auto;
    gap:5.5rem;
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
        flex-direction:column;
        gap:2rem;
        } 
    h2 {
        margin-bottom:0;
    }
`

const Illustration = styled.div`   
    width:320px;
    @media (max-width: ${props => props.theme.breakpoints[0]}) {
    width:100%;
} 
`

const NewsResumeBox = ({titre, date, image, slug, detail}) => {

    return (
        <Flex to={`/discographie/${slug}`} title={titre} className="disque-link">
            <Illustration> 
                <Fade left duration={400} distance="25px">
                    <GatsbyImage alt={titre} image={image.gatsbyImageData} />
                </Fade>
            </Illustration>
            <div className="news-resume-box">   
                <Fade up duration={400} distance="25px">
                    <h2 >{titre}</h2>
                    <span><FormatDate date={date} formatDate="yyyy"/></span>
                </Fade>
            </div>
            
        </Flex>
        
     )
}
export default NewsResumeBox